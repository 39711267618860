<template>
  <div>
    <!-- Stats Card Vertical -->
    <b-row class="match-height">
      <b-col
        xl="4"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          class="hvr-shrink hvr-underline-from-left"
          icon="BarChartIcon"
          statistic="dashboard"
          statistic-title="dashboard_description"
          color="info"
          @click.native="(e)=>onCardClicked('dashboard','dashboard-guide')"
        />
      </b-col>
      <b-col
        xl="4"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          class="hvr-shrink hvr-underline-from-left"
          color="warning"
          icon="HomeIcon"
          statistic="administration"
          statistic-title="administration_description"
          @click.native="(e)=>onCardClicked('administration','admin-guide')"
        />
      </b-col>
      <b-col
        xl="4"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          class="hvr-shrink hvr-underline-from-left"
          color="danger"
          icon="UsersIcon"
          statistic="students"
          statistic-title="students_description"
          @click.native="(e)=>onCardClicked('students','students-guide')"
        />
      </b-col>
      <b-col
        v-if="hasModule('academics')"
        xl="4"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          class="hvr-shrink hvr-underline-from-left"
          color="primary"
          icon="BookIcon"
          statistic="academics"
          statistic-title="academics_description"
          @click.native="(e)=>onCardClicked('academics','academics-guide')"
        />
      </b-col>
      <b-col
        v-if="hasModule('finance')"
        xl="4"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          class="hvr-shrink hvr-underline-from-left"
          color="success"
          icon="DollarSignIcon"
          statistic="finance"
          statistic-title="finance_description"
          @click.native="(e)=>onCardClicked('finance','finance-guide')"
        />
      </b-col>
      <b-col
        v-if="hasModule('staff')"
        xl="4"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          class="hvr-shrink hvr-underline-from-left"
          color="warning"
          icon="UserIcon"
          statistic="staff"
          statistic-title="staff_description"
          @click.native="(e)=>onCardClicked('staff','staff-guide')"
        />
      </b-col>
      <b-col
        v-if="hasModule('documents')"
        xl="4"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          class="hvr-shrink hvr-underline-from-left"
          color="success"
          icon="FileTextIcon"
          statistic="Documents"
          statistic-title="Manage and share documents"
          @click.native="(e)=>onCardClicked('documents','docs-guide')"
        />
      </b-col>
      <b-col
        v-if="hasModule('transportation')"
        xl="4"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          class="hvr-shrink hvr-underline-from-left"
          color="success"
          icon="TruckIcon"
          statistic="transportation"
          statistic-title="transportation_description"
          @click.native="(e)=>onCardClicked('transportation','transportation-guide')"
        />
      </b-col>
<!--      <b-col-->
<!--        v-if="hasModule('transportation')"-->
<!--        xl="4"-->
<!--        md="4"-->
<!--        sm="6"-->
<!--      >-->
<!--        <statistic-card-vertical-->
<!--          class="hvr-shrink hvr-underline-from-left"-->
<!--          color="warning"-->
<!--          icon="CheckCircleIcon"-->
<!--          statistic="smartmarker"-->
<!--          statistic-title="marker_description"-->
<!--          @click.native="(e)=>onCardClicked('smartmarker','smartmarker-guide')"-->
<!--        />-->
<!--      </b-col>-->
      <b-col
        xl="4"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          class="hvr-shrink hvr-underline-from-left"
          color="danger"
          icon="SettingsIcon"
          statistic="settings"
          statistic-title="settings_description"
          @click.native="(e)=>onCardClicked('accounts','accounts-guide')"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import { CURRENT_ACADEMIC_YEAR_Q } from '@/graphql/queries'
import logData from '@/libs/log'
import teachers from '@/lenon/mixins/teachers'

export default {
  components: {
    BRow,
    BCol,
    StatisticCardVertical,
  },
  mixins: [teachers],
  data() {
    return {
    }
  },
  mounted() {
    this.getCurrentAcademicYear()
  },
  methods: {
    hasModule(module) {
      const modules = this.$store.getters['auth/schoolModules']
      return modules.includes(module)
    },
    getCurrentAcademicYear() {
      this.$apollo.query({
        query: CURRENT_ACADEMIC_YEAR_Q,
      }).then(res => {
        if (!res.errors && res.data != null) {
          this.$store.commit('auth/setCurrentAcademicYear', res.data)
          this.$store.commit('auth/setModules', res.data.currentAcademicYear.modules)
        }
      }).catch(err => {
        logData(err)
      })
    },
    onCardClicked(module, route) {
      if (module === 'documents' && this.$store.getters['termsClasses/teachers'].length === 0) {
        this.getTeachers()
      }
      if (module === 'transportation') {
        if (this.$store.getters['accountUsers/users'].length === 0) {
          this.getUsers()
        }
        if (this.$store.getters['vehicle/vehicles'].length === 0) {
          this.getVehicles()
        }
        if (this.$store.getters['pickupPoint/pickupPoints'].length === 0) {
          this.getPickupPoints()
        }
      }
      if (module === 'dashboard') {
        if (this.$store.getters['pickupPoint/pickupPoints'].length === 0) {
          this.getPickupPoints()
        }
      }
      this.$store.commit('auth/setCurrentModule', module)
      const lastRoute = localStorage.getItem(module)
      if (lastRoute) {
        // eslint-disable-next-line no-param-reassign
        route = lastRoute
      }
      this.$router.push({ name: route })
    },
  },
}
</script>

<style lang="scss">
    @import "~hover.css";
    .hvr-underline-from-left:before {
        background: #fa2 !important;
    }
</style>
